<template>
  <div>
    <div class="mt-4">
      <p class="text-xs text-grey-2">Send amount</p>
      <p class="text-dark font-bold">{{userData.receiveAmount | formatMoney(userData.receiveCurrency)}}</p>
    </div>
    <div>
      <p class="text-xs text-grey-2">Charges</p>
      <p class="text-grey text-xs" v-if="userData.fee">{{userData.fee.fee | formatMoney(userData.fee.currency)}}</p>
    </div>
    <!-- <div class="mt-4">
      <p class="text-xs text-grey-2">Beneficiary gets</p>
      <p class="text-dark font-bold">$10,000</p>
    </div> -->
    <Card p="p-4" background="bg-lightGrey-1" class="mt-4">
    <!-- <div>
      <p class="text-xs text-grey-2">Charges</p>
      <p class="text-dark text-sm" v-if="userData.fee">{{userData.fee.currency}}{{userData.fee.fee | formatMoney}}</p>
    </div> -->
    <div class="grid grid-cols-2">
      <p class="text-xs text-grey-2">Name</p>
      <p class="text-grey text-xs text-right font-semibold uppercase">{{userData.beneficiary.name}}</p>
    </div>
    <div class="mt-4 grid grid-cols-2" v-if="userData.beneficiary && userData.beneficiary.email">
      <p class="text-xs text-grey-2">Beneficiary's email</p>
      <p class="text-grey text-xs text-right font-semibold">{{userData.beneficiary.email}}</p>
    </div>
    <div class="grid grid-cols-2 text-sm mt-4">
      <p class="text-grey text-xs">Account number/IBAN</p>
      <p class="text-grey text-right text-xs font-semibold">{{userData.beneficiary.accountNumber}}</p>
  </div>
    <div class="mt-4 grid grid-cols-2">
      <p class="text-xs text-grey-2">Swift code/routing no.</p>
      <p class="text-grey text-xs text-right font-semibold">{{userData.beneficiary.swiftCode || userData.beneficiary.routingNumber }}</p>
    </div>
    <div class="mt-4 grid grid-cols-2" v-if="userData.beneficiary && userData.beneficiary.country">
      <p class="text-xs text-grey-2">Country</p>
      <p class="text-grey text-xs text-right font-semibold">{{userData.beneficiary.country | capitalize}}</p>
    </div>
    <div class="mt-4 grid grid-cols-2" v-if="userData.beneficiary && userData.beneficiary.bankName">
      <p class="text-xs text-grey-2">Bank name</p>
      <p class="text-grey text-xs text-right font-semibold">{{userData.beneficiary.bankName}}</p>
    </div>
    </Card>
    <Button text="Confirm payment" width="min-w-44" class="mt-4" @click="submitDataForPayment" :loading="submitLoading" />
  </div>
</template>
<script>
import {mapActions, mapState} from 'vuex'
import {Button} from '@/UI/Button'
import {Modal} from '@/UI/Modals'
// import {ALL_PERMISSIONS} from '@/utils/sharedData/userManagement'
import {MAKE_PAYMENT, EDIT_PAYMENT_DETAILS, INITITATE_OFF_WALET_PAYMENT, RECORD_COMPLETED_OFF_WALLET_TNX } from '@/utils/api/wire'
export default {
    components:{
        Button,
        Modal
    },
    props:{
      userData:{
        type: Object,
        default: () => {},
        required: false
      },
      wireAccountData:{
            type: Object,
            default:() => {}
      },
      editPaymentDetails:{
        type: Object,
        default:() => {}
      },
      makePaymentTxType:{
        type: String,
        default:'',
        required: false
      }
    },
    computed:{
      ...mapState({ 
        // permissions:(state) => state?.dashboard?.dashboardData?.userPermissions[0]?.permissions
      })
    },
    data:() => ({
      submitLoading: false,
      showSuccess: false,
      // allPermissions:ALL_PERMISSIONS
    }),
    methods:{
      ...mapActions('payments', ['createWirePayment', 'getAllPayments']),
      ...mapActions('notification', ['showAlert']),
      submitDataForPayment(){
        const scheduleTypeIsRate = this.userData.wirePaymentScheduleType === 'RATE'
        this.submitLoading = true;
        let data = {
          // receiveAmount: $formatCurrencyStringToInt(this.userData.receiveAmount),
          receiveAmount: this.userData.receiveAmount,
          receiveCurrency: this.userData.receiveCurrency,
          invoiceMediaId: this.userData.invoiceMediaId,
          purposeOfPayment: this.userData.purposeOfPayment,
          beneficiary: this.userData.beneficiary,
          scheduledRate : scheduleTypeIsRate ? this.userData.scheduledRate : null,
          scheduledDate: scheduleTypeIsRate ? this.userData.scheduledDate : null,
          wirePaymentScheduleType: scheduleTypeIsRate ? 'RATE' : null,
          purposeOfPaymentCode : this.userData.purposeOfPaymentCode
        };
        // data.beneficiary.financialSystemCode = this.userData.financialSystemCode
        delete data.beneficiary.countrySpecificData

        if(this.makePaymentTxType.length){
          if(this.makePaymentTxType === 'make'){
            data.exchangeRate = this.userData.exchangeRate
            this.createWirePaymentForInitiateOffWallet(data)
          }else if(this.makePaymentTxType === 'record'){
            data.exchangeRate = this.userData.exchangeRate;
            data.transactionCostInSendCurrency = this.userData.transactionCostInSendCurrency;
            data.paymentCreatedAt = this.userData.paymentCreatedAt;
            this.createWirePaymentForRecordOffWallet(data);

          }
          return
        }

        if(this.$route.query.edit_payment){
          data.paymentId = this.editPaymentDetails?.id;
          data.paymentVersion = this.editPaymentDetails?.versionNumber
          this.submitEditPayment({
            data:data,
            wireAccountId: this.wireAccountData.wireAccount.id
          })
        }else{
          this.submitMakePayment(data)
        }

      },
      handleSuccess(){
        this.$emit('success')
      },
      submitMakePayment(data){
        MAKE_PAYMENT({
          data: data,
          wireAccountId: this.wireAccountData.wireAccount.id
        }).then(() => {
          this.submitLoading = false;
          this.showAlert({
            display: true,
            description: 'We are currently processing your payment and will send you an email with its status shortly',
            title:'Payment processing',
            callback: this.handleSuccess(),
            type: 'success'
          })
          // let url = '/bnpl/v1/wire-payment/by-account?'
          // this.getAllPayments({params: url})
          this.showSuccess = true
        }).catch(() => {
          this.submitLoading = false;
        })
    },
    submitEditPayment(data){
        EDIT_PAYMENT_DETAILS(data)
          .then(() => {
            this.submitLoading = false;
            this.showAlert({
              display: true,
              description: 'Payment successfully edited',
              title:'Payment Edited',
              callback: this.handleSuccess(),
              type: 'success'
            })
            this.showSuccess = true
          }).catch(() => {
            this.submitLoading = false
          })
      },
      createWirePaymentForInitiateOffWallet(data){
        INITITATE_OFF_WALET_PAYMENT({data: data, wireAccountId: this.wireAccountData.wireAccount.id})
          .then(() => {
            this.submitLoading = false;
            this.showAlert({
              display: true,
              description: 'Payment initiation successful',
              title:'Payment Edited',
              callback: this.handleSuccess(),
              type: 'success'
            })
            this.showSuccess = true
          }).catch(() => {
            this.submitLoading = false
          })

      },
      createWirePaymentForRecordOffWallet(data){
        RECORD_COMPLETED_OFF_WALLET_TNX({data: data, wireAccountId: this.wireAccountData.wireAccount.id})
          .then(() => {
            this.submitLoading = false;
            this.showAlert({
              display: true,
              description: 'Record successful',
              title:'Payment Edited',
              callback: this.handleSuccess(),
              type: 'success'
            })
            this.showSuccess = true
          }).catch(() => {
            this.submitLoading = false
          })
      }
    },
}
</script>
